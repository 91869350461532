const ExerciseTypes = [
  {
    id: 1,
    type: "rise_fall",
    name: "Balony",
    description: "Wybierz jedną ze zmieniających się odpowiedzi na wyświetlone pytanie.",
  },
  {
    id: 2,
    type: "tiles",
    name: "Kafle",
    description: "Wybierz jedną z odpowiedzi na wyświetlone pytanie.",
  },
  {
    id: 3,
    type: "train",
    name: "Pociąg",
    description: "Wybierz, czy wyświetlona odpowiedź jest prawidłową.",
  },
  {
    id: 4,
    type: "shooting",
    name: "Strzelanka",
    description: "Zestrzel odpowiedzi, które są prawidłową odpowiedzią na wyświetlone pytanie.",
  },
  {
    id: 5,
    type: "choices",
    name: "Kafle (bez punktów)",
    description: "Wybierz kilka odpowiedzi.",
  },
  {
    id: 6,
    type: "message",
    name: "Wiadomość",
    description: "Wyświetl wiadomość.",
  },
  {
    id: 7,
    type: "memory",
    name: "Memory",
    description: "Odkrywaj pary obrazków.",
  },
  {
    id: 8,
    type: "reveal",
    name: "Zakryte kafle (wielokrotny wybór)",
    description: "Odkrywaj pojedyncze odpowiedzi, aby odnaleźć te właściwe.",
  },
  {
    id: 9,
    type: "elevator",
    name: "Winda",
    description: "Wysiądź na piętrze z prawidłową odpowiedzią.",
  },
  {
    id: 10,
    type: "doors",
    name: "Drzwi",
    description: "Otwórz drzwi kluczem z prawidłową odpowiedzią.",
  },
  {
    id: 11,
    type: "open-question",
    name: "Otwarte pytanie",
    description: "Odpowiedz na pytanie otwarte.",
  },
  {
    id: 12,
    type: "match",
    name: "Dopasowywanie",
    description: "Dopasowywanie wyświetlonych odpowiedzi do kategorii",
  },
  {
    id: 13,
    type: "voting",
    name: "Ankieta",
    description: "Odpowiedz na pytanie w skali 5 punktowej",
  },
  {
    id: 14,
    type: "parachutes",
    name: "Spadochrony",
    description: "Odpowiadanie na pytania spadające na spadochronach",
  },
  {
    id: 15,
    type: "cauldron",
    name: "Kocioł",
    description: "Wkładaj do kotła odpowiedzi, aby sprawdzić, czy są prawdziwe",
  },
  {
    id: 16,
    type: "cleaning",
    name: "Sprzątanie",
    description: "Wyrzuć do kosza odpowiedzi, które nie są prawdziwe",
  },
  {
    id: 17,
    type: "puzzle",
    name: "Układanka",
    description: "Wybierz prawidłową odpowiedź, aby wypełnić układankę",
  },
  {
    id: 18,
    type: "true_false",
    name: "Prawda czy fałsz?",
    description: "Wybierz, czy wyświetlone stwierdzenie to prawda czy fałsz?",
  },
  {
    id: 19,
    type: "bubbles",
    name: "Bąbelki",
    description: "Zestrzel bąbelki z prawidłowymi odpowiedziami",
  },
  {
    id: 20,
    type: "conversations",
    name: "Konwersacje",
    description: "Wybieraj prawidłowe odpowiedzi w konwersacji",
  },
  {
    id: 21,
    type: "distribution",
    name: "Podział",
    description: "Wybieraj rozkład odpowiedzi",
  },
  {
    id: 22,
    type: "values",
    name: "Wartości",
    description: "Wybierz wartości spośród listy w dwóch rundach",
  },
]

export default ExerciseTypes
