import DefaultInfo from "./_default"
import {newExerciseWithTime} from "./common/exercise"
import { answerWithFeedback } from "./common/answer"

class TruckInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...newExerciseWithTime,
    ],
  }

  static answer = {
    ...DefaultInfo.answer,
    parameters: [...answerWithFeedback],
  }
}

export default TruckInfo
