import React, { useEffect, useState } from "react"
import config from "react-global-configuration"
import { Col, Grid, Row } from "react-flexbox-grid"
import {
  Button,
  Callout,
  Card,
  Collapse,
  Elevation,
  FormGroup,
  H1,
  InputGroup,
  Intent,
} from "@blueprintjs/core"
import { connect } from "react-redux"

import Auth from "../../actions/auth"

import backgroundImg from "./img/login-bg.jpg"
import "./Login.scss"
import { refreshServerUrl, setServerUrl } from "../../config"

function Login({ loggedIn, loggingIn, checkAuthorizationForUserAndPassword }) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [server, setServer] = useState(config.get("serverAddress"))
  const [triedLoggingIn, setTriedLoggingIn] = useState(false)
  const [authorizationError, setAuthorizationError] = useState(false)

  useEffect(() => {
    if (!loggingIn && triedLoggingIn) {
      setAuthorizationError(true)
    }
  }, [loggingIn, triedLoggingIn])

  function checkAuthorization(event) {
    setServerUrl(server)
    refreshServerUrl()
    event.preventDefault()
    setAuthorizationError(false)
    setTriedLoggingIn(true)
    checkAuthorizationForUserAndPassword(username, password)
    return false
  }

  const style = {
    backgroundImage: `url('${backgroundImg}')`,
  }

  return (
    <div className="Login" style={style}>
      <Grid>
        <Row center="xs">
          <Col md={8} lg={6}>
            <Card className="form-container" elevation={Elevation.TWO}>
              <H1>Zaloguj się</H1>
              <form onSubmit={checkAuthorization}>
                <FormGroup label="Użytkownik" labelFor="username">
                  <InputGroup
                    id="username"
                    placeholder="Użytkownik"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormGroup>
                <FormGroup label="Hasło" labelFor="password">
                  <InputGroup
                    id="password"
                    type="password"
                    placeholder="Hasło"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
                <FormGroup label="Serwer" labelFor="server">
                  <InputGroup
                    id="server"
                    placeholder="Server"
                    value={server}
                    onChange={(e) => setServer(e.target.value)}
                  />
                </FormGroup>
                <Collapse isOpen={authorizationError}>
                  <Callout
                    intent={Intent.DANGER}
                    title="Nieprawidłowa nazwa użytkownika i/lub hasło"
                  />
                </Collapse>
                <Button
                  type="submit"
                  large
                  loading={loggingIn}
                  intent={Intent.PRIMARY}
                  icon="key"
                  text="Zaloguj"
                />
              </form>
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { global, auth } = state

  return {
    loggedIn: auth.loggedIn,
    loggingIn: global.processing.show,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthorizationForUserAndPassword: (username, password) => {
      dispatch(Auth.checkAuthorizationForUserAndPassword(username, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
