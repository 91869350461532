import DefaultInfo from "./_default"

class DistributionInfo extends DefaultInfo {
  static answer = {
    hasNoCorrectIncorrect: true,
    parameters: [],
  }

  static newQuestion = () => ({
    content: "",
    parameters: {},
    answers: [this.newAnswer(), this.newAnswer(), this.newAnswer(), this.newAnswer()],
  })
}

export default DistributionInfo
