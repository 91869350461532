import DefaultInfo from "./_default"
import FIELD_TYPES from "../fieldTypes"

class ValuesInfo extends DefaultInfo {
  static answer = {
    hasNoCorrectIncorrect: true,
    parameters: [],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "summary",
        type: FIELD_TYPES.bool,
        description: "Podsumowanie?",
        default: false,

        inline: true,
        small: true,
      },
    ],
  }

  static newQuestion = () => ({
    content: "",
    parameters: { summary: false },
    answers: [this.newAnswer(), this.newAnswer(), this.newAnswer(), this.newAnswer()],
  })
}

export default ValuesInfo
